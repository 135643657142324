
import RedsCard from './../components/reds-card/RedsCard';
import {redslist} from './../helpers/RedsList';


const TopRedsHotMap = () => {


    const topHotReds = redslist.map((red, index) => ({ ...red, originalIndex: index })).filter((red) => red.status === "hot");
    return (

        <main className="section">
        <div className="container">
            <h2 className="title-1">Top 3 hot REDs</h2>
            <ul className="projects">
                
             {
             topHotReds.reverse().slice(0, 3).map((red, index) => { 
             //hotReds.slice(0).reverse().map((red, index) => { 
                    return (
                    <RedsCard 
                        key={red.name} 
                        index={red.originalIndex} 
                        title={red.title} 
                        img={red.img} 
                        link={red.link}
                        checkBtn={red.checkBtn}
                        buyLink={red.buyLink}
                    />)
                })}
               

            </ul>
        </div>
    </main>
     );
}                   
                    
                
 
export default TopRedsHotMap