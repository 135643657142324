


import Monkeri from './../img/reds/Monkeri.jpg'
import Cerebella from './../img/reds/Cerebella.jpg'
import Elfistera from './../img/reds/Elfistera.jpg'
import Krestio from './../img/reds/Krestio.jpg'
import Sceptro from './../img/reds/Sceptro.jpg'
import Crusade from './../img/reds/Crusade.jpg'
import Hemeralop from './../img/reds/Hemeralop.jpg'
import Skulloria from './../img/reds/Skulloria.jpg'
import Crossarch from './../img/reds/Crossarch.jpg'
import Astraline from './../img/reds/Astraline.jpg'
import Defestra from './../img/reds/Defestra.jpg'
import Astrospearum from './../img/reds/Astrospearum.jpg'
import Fibraria from './../img/reds/Fibraria.jpg'
import Ribergio from './../img/reds/Ribergio.jpg'
import Echovia from './../img/reds/Echovia.jpg'
import Solarchime from './../img/reds/Solarchime.jpg'
import Astraldust from './../img/reds/Astraldust.jpg'
import Versteria from './../img/reds/Versteria.jpg'
import Exosphira from './../img/reds/Exosphira.jpg'
import Stellonstell from './../img/reds/Stellonstell.jpg'
import Stellronex from './../img/reds/Stellronex.jpg'
import Astrosigna from './../img/reds/Astrosigna.jpg'
import Arachunt from './../img/reds/Arachunt.jpg'
import Zorium from './../img/reds/Zorium.jpg'
import Ecliptica from './../img/reds/Ecliptica.jpg'
import Adernanta from './../img/reds/Adernanta.jpg'
import Regalvea from './../img/reds/Regalvea.jpg'
import Opalaxis from './../img/reds/Opalaxis.jpg'
import Neuroclotum from './../img/reds/Neuroclotum.jpg'
import Ribocortex from './../img/reds/Ribocortex.jpg'
import Arachneuro from './../img/reds/Arachneuro.jpg'


const images = 

    {
        Monkeri: Monkeri,
        Cerebella: Cerebella,
        Elfistera: Elfistera,
        Krestio: Krestio,
        Sceptro: Sceptro,
        Crusade: Crusade,
        Hemeralop: Hemeralop,
        Skulloria: Skulloria,
        Crossarch: Crossarch,
        Astraline: Astraline,
        Defestra: Defestra,
        Astrospearum: Astrospearum,
        Fibraria: Fibraria,
        Ribergio: Ribergio,
        Echovia: Echovia,
        Solarchime: Solarchime,
        Astraldust: Astraldust,
        Versteria: Versteria,
        Exosphira: Exosphira,
        Stellonstell:Stellonstell,
        Stellronex: Stellronex,
        Astrosigna: Astrosigna,
        Arachunt: Arachunt,
        Zorium: Zorium,
        Ecliptica: Ecliptica,
        Adernanta: Adernanta,
        Regalvea: Regalvea,
        Opalaxis: Opalaxis,
        Neuroclotum: Neuroclotum, 
        Ribocortex: Ribocortex,
        Arachneuro: Arachneuro
    }


export {images}