
import { NavLink } from "react-router-dom";
import Embeds from "./Embeds"
import { images } from "./Images"


const redslist = [

    // statuses: hot, act, done

    {   tokenId: 30,
        name: 'Monkeri',
        title: 'Monkeri RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Monkeri". 5% of sales of physical Monkeri physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Monkeri,
        safe: '',   
        embed: <Embeds tokenId={30} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 29,
        name: 'Cerebella',
        title: 'Cerebella RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Cerebella". 5% of sales of Cerebella physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Cerebella,
        safe: '',   
        embed: <Embeds tokenId={29} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 28,
        name: 'Elfistera',
        title: 'Elfistera RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Elfistera". 5% of sales of physical Elfistera physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Elfistera,
        safe: '',   
        embed: <Embeds tokenId={28} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 27,
        name: 'Krestio',
        title: 'Krestio RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Krestio". 5% of sales of physical Krestio physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Krestio,
        safe: '',   
        embed: <Embeds tokenId={27} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 26,
        name: 'Sceptro',
        title: 'Sceptro RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Sceptro". 5% of sales of physical Sceptro physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Sceptro,
        safe: '',   
        embed: <Embeds tokenId={26} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 25,
        name: 'Crusade',
        title: 'Crusade RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Crusade". 5% of sales of physical Crusade physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Crusade,
        safe: '',   
        embed: <Embeds tokenId={25} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 24,
        name: 'Hemeralop',
        title: 'Hemeralop RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Hemeralop". 5% of sales of Hemeralop physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Hemeralop,
        safe: '',   
        embed: <Embeds tokenId={24} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 23,
        name: 'Skulloria',
        title: 'Skulloria RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Skulloria". 5% of sales of Skulloria physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Skulloria,
        safe: '',   
        embed: <Embeds tokenId={23} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 22,
        name: 'Crossarch',
        title: 'Crossarch RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Crossarch". 5% of sales of physical Crossarch physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Crossarch,
        safe: '',   
        embed: <Embeds tokenId={22} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 21,
        name: 'Astraline',
        title: 'Astraline RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Astraline". 5% of sales of physical Astraline physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Astraline,
        safe: '',   
        embed: <Embeds tokenId={21} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 20,
        name: 'Defestra',
        title: 'Defestra RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Defestra". 5% of sales of physical Defestra physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Defestra,
        safe: '',   
        embed: <Embeds tokenId={20} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },

    {   tokenId: 19,
        name: 'Astrospearum',
        title: 'Astrospearum RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Astrospearum". 5% of sales of physical Astrospearum physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Astrospearum,
        safe: '',   
        embed: <Embeds tokenId={19} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },




    {   tokenId: 18,
        name: 'Fibraria',
        title: 'Fibraria RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Fibraria". 5% of sales of Fibraria physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Fibraria,
        safe: '',   
        embed: <Embeds tokenId={18} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/`,
        status: 'hide',
    },
    
    {   tokenId: 17,
        name: 'Ribergio',
        title: 'Ribergio RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Ribergio". 5% of sales of Ribergio physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Ribergio,
        safe: '0xf710d54ef6E2698263928056f37B7503f79d1532',   
        embed: <Embeds tokenId={17} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: `https://polygonscan.com/address/0xf710d54ef6E2698263928056f37B7503f79d1532`,
        status: 'act',
    },

    {   tokenId: 16,
        name: 'Echovia',
        title: 'Echovia RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Echovia". 5% of sales of Echovia physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Echovia,
        safe: '0x2ECe36188c58418e409a65C3EBf43Fa8f3f7C58c',   
        embed: <Embeds tokenId={16} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x2ECe36188c58418e409a65C3EBf43Fa8f3f7C58c',
        status: 'hot',
    },

    {   tokenId: 15,
        name: 'Solarchime',
        title: 'Solarchime RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Solarchime". 5% of sales of Solarchime physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Solarchime,
        safe: '0x27Dd28141480e4dAb9e7b67A570bcE5802fc727e',   
        embed: <Embeds tokenId={15} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x27Dd28141480e4dAb9e7b67A570bcE5802fc727e',
        status: 'hot',
    },

    {   tokenId: 14,
        name: 'Astraldust',
        title: 'Astraldust RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Astraldust". 5% of sales of Astraldust physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Astraldust,
        safe: '0x0cd91A8bd92Cc545d886B0984E57268F57693EFF',   
        embed: <Embeds tokenId={14} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x0cd91A8bd92Cc545d886B0984E57268F57693EFF',
        status: 'hot',
    },

    {   tokenId: 13,
        name: 'Versteria',
        title: 'Versteria RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Versteria". 5% of sales of Versteria physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Versteria,
        safe: '0x7a66695ea10B119C84F3e54B152558644E8fb5fE',   
        embed: <Embeds tokenId={13} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x7a66695ea10B119C84F3e54B152558644E8fb5fE',
        status: 'act',
    },

    {   tokenId: 12,
        name: 'Exosphira',
        title: 'Exosphira RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Exosphira". 5% of sales of physical Exosphira pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Exosphira,
        safe: '0xa86b70563ad35320b6B7deC40b4dB8FF43566e36',   
        embed: <Embeds tokenId={12} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xa86b70563ad35320b6B7deC40b4dB8FF43566e36',
        status: 'act',
    },

    {   tokenId: 11,
        name: 'Stellonstell',
        title: 'Stellonstell RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Stellonstell". 5% of sales of Stellonstell physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Stellonstell,
        safe: '0x82BB72D52f789F9Dc9B1C348acb68a3fFD46f2cd',   
        embed: <Embeds tokenId={11} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x82BB72D52f789F9Dc9B1C348acb68a3fFD46f2cd',
        status: 'act',
    },

    {   tokenId: 10,
        name: 'Stellronex',
        title: 'Stellronex RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Stellronex". 5% of sales of Stellronex physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Stellronex,
        safe: '0x3939BeE097A8ba2C6B4f143AE93B1A6FD7a0f3A0',   
        embed: <Embeds tokenId={10} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x3939BeE097A8ba2C6B4f143AE93B1A6FD7a0f3A0',
        status: 'act',
    },

    {   tokenId: 9,
        name: 'Astrosigna',
        title: 'Astrosigna RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Astrosigna". 5% of sales of Astrosigna physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Astrosigna,
        safe: '0x371A9794879ee936f3aFC4eAEA8E8bEBde377633',   
        embed: <Embeds tokenId={9} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x371A9794879ee936f3aFC4eAEA8E8bEBde377633',
        status: 'hot',
    },

    {   tokenId: 8,
        name: 'Arachunt',
        title: 'Arachunt RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Arachunt". 5% of sales of Arachunt physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Arachunt,
        safe: '0x7d0f8A43c7D1dB9A0b25b40B6afAA58f46f59bB6',          
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x7d0f8A43c7D1dB9A0b25b40B6afAA58f46f59bB6',
        status: 'done',
    },
       
    {   tokenId: 7,
        name: 'Zorium',
        title: 'Zorium RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Zorium". 5% of sales of Zorium physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Zorium,
        safe: '0xFDb0B37fDb790926aD1B1e5E59CA867a30EEe05b',             
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xFDb0B37fDb790926aD1B1e5E59CA867a30EEe05b',
        status: 'done',
    },

    {   tokenId: 6,
        name: 'Ecliptica',
        title: 'Ecliptica RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Ecliptica". 5% of sales of Ecliptica physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Ecliptica,
        safe: '0x4924dB1FfaC503Fbb36Ed01de00CD929f65d18E9',   
        embed: <Embeds tokenId={6} />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x4924dB1FfaC503Fbb36Ed01de00CD929f65d18E9',
        status: 'hot',
    },

    {   tokenId: 5,
        name: 'Adernanta',
        title: 'Adernanta RED card',
        descr: 'This RED card is a proof of your contribution to the discovery of a bio artifact, codenamed "Adernanta". 5% of sales of Adernanta physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Adernanta,
        safe: '0xa057Da78289d0b8ED4DFbA88a572Cd2955b359Ae',      
        link: <NavLink to='reds/:id'></NavLink>,
        embed: <Embeds tokenId={5} />,  
        checkLink: 'https://polygonscan.com/address/0xa057Da78289d0b8ED4DFbA88a572Cd2955b359Ae',
        status: 'hot',    
    },

    {   tokenId: 4,
        name: 'Regalvea',
        title: 'Regalvea RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Regalvea". 5% of sales of Regalvea physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Regalvea,
        safe: '0xD01C8b2eDC93DaD56263309688A19373c846A904',           
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xD01C8b2eDC93DaD56263309688A19373c846A904',
        status: 'done',
    },

    {   tokenId: 3,
        name: 'Opalaxis',
        title: 'Opalaxis RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of a technical device, codenamed "Opalaxis". 5% of sales of physical Opalaxis pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Opalaxis,
        safe: '0x57B914D256aAEF888c4F092f9351bf2F1f731bF2',    
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x57B914D256aAEF888c4F092f9351bf2F1f731bF2',
        status: 'done',
    },

    {   tokenId: 2,
        name: 'Neuroclotum',
        title: 'Neuroclotum RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Neuroclotum". 5% of sales of Neuroclotum physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: images.Neuroclotum,
        safe: '0xebc803Beb210d26ad8015e9287B2d402Cd3A992c', 
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xebc803Beb210d26ad8015e9287B2d402Cd3A992c',
        status: 'done',
    },      

    {   tokenId: 1,
        name: 'Ribocortex',
        title: 'Ribocortex RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Ribocortex". 5% of sales of Ribocortex physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC', 
        img: images.Ribocortex, 
        safe: '0xD809b96bC061280b4a8bb646fDcF66781D378AC7',   
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xD809b96bC061280b4a8bb646fDcF66781D378AC7',
        status: 'done',
    }, 

    {   tokenId: 0,
        name: 'Arachneuro',
        title: 'Arachneuro RED card', 
        descr: 'This RED card is a proof of your contribution to the discovery of an extraterrestrial life form, codenamed "Arachneuro". 5% of sales of Arachneuro physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.', 
        income: 'From 0.11 $MATIC',     
        img: images.Arachneuro,
        safe: '0x1331ce7361418fE9303C37C208CCF49a38da11F2', 
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x1331ce7361418fE9303C37C208CCF49a38da11F2',
        status: 'done',
    }, 

]

export {redslist}