import { useState } from 'react';
import RedsCard from './../components/reds-card/RedsCard';
import { redslist } from './../helpers/RedsList';

const RedsActMap = () => {
  const itemsPerPage = 12; // Количество элементов на странице
  // const [currentPage, setCurrentPage] = useState(1); // Текущая страница - вроде работает и без этой строки
  const [loadedItems, setLoadedItems] = useState(itemsPerPage); // Количество загруженных элементов

  // Обработчик нажатия на кнопку "Show more"
  const handleShowMore = () => {
    setLoadedItems((prevLoadedItems) => prevLoadedItems + itemsPerPage);
  };

  // Фильтрация элементов со статусом "done" и ограничение по количеству загруженных элементов
  const actReds = redslist
    .map((red, index) => ({ ...red, originalIndex: index }))
    .filter((red) => red.status === "hot" || red.status === "act")
    .slice(0, loadedItems);

  return (
    <main className="section">
      <div className="container">
        <h2 className="title-1">Active REDs</h2>
        <ul className="projects">
          {actReds.map((red, index) => (
            <RedsCard
              key={red.name}
              index={red.originalIndex}
              title={red.title}
              img={red.img}
              link={red.link}
              checkBtn={red.checkBtn}
              buyLink={red.buyLink}
            />
          ))}
        </ul>
        {loadedItems < redslist.filter((red) => red.status === "hot" || red.status === "act").length && (
          <div className="btn-placeholder" >
          <button onClick={handleShowMore} className="btn">
            Show previous
          </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default RedsActMap;
